/*LAYOUT*/
$container-fluid:        1300px;
$grid-gutter-width: 20px;

/*FONT*/
$body-font-size:   16px;

/*COLORS*/
$blue:        #008acc;
$blue-lighter: #0051FA;
$black:       #000000;
$white:       #ffffff;
$border:      #cdd3d5;
$grey:   	  #646464;
$graytext:  	  #646464;

/*HEADINGS*/
$h1-size:          30px;
$h2-size:          30px;
$h3-size:          24px;
$h4-size:          18px;
$h5-size:          16px;
$h6-size:          14px;

/*Breakpoints*/
$xlarge: 1280px;
$large: 1024px;
$tablet: 767px;
$tablet-min: 768px;
$small: 640px;
