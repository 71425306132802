footer {
	display: inline-block;
	width: 100%;
	background: $black;
	color: $white;
	padding: 30px 0 35px;

	.t-b {
		@media (max-width: $tablet) {
			margin-bottom: 30px;
		}
	}

	a {
		color: $white;

		&:hover,
		&:focus {
			color: $white;
		}
	}

	ul {
		padding: 0;
		margin: 0;

		@media (max-width: $tablet) {
			margin-bottom: 30px;
		}

		li {
			list-style: none;
			margin-bottom: 20px;
			padding-left: 20px;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				position: relative;
				width: 100%;
				display: inline-block;

				@media (max-width: $tablet) {
					font-size: 14px;

					&::after {
						position: absolute;
						left: -21px;
						top: 0;
						width: 7px;
						height: 21px;
						background: $blue;
						content: "";
					}
				}

				&:hover {
					color: #8e8f90;

					&::after {
						position: absolute;
						left: -21px;
						top: 0;
						width: 7px;
						height: 21px;
						background: $blue;
						content: "";
					}
				}
			}
		}
	}

	p {
		margin: 0;

		a {
			color: $blue;

			&:hover,
			&:focus {
				color: $blue;
				text-decoration: underline;
			}
		}
	}

	h4 {
		color: #8e8f90;
		font-size: 18px;
		margin-bottom: 20px;
		text-transform: uppercase;
	}

	.footer-header {
		border-bottom: 1px #3f4142 solid;
		padding-bottom: 24px;
		margin-bottom: 58px;

		@media (max-width: $tablet) {
			padding-bottom: 20px;
			margin-bottom: 30px;
		}
	}

	.footer-copy {
		border-top: 1px #3f4142 solid;
		padding-top: 34px;
		margin-top: 58px;
		color: #8e8f90;
		font-size: 16px;

		@media (max-width: $tablet) {
			font-size: 13px;
			text-align: center;
			margin-top: 30px;
			padding-top: 20px;

			.text-right {
				text-align: center !important;
			}
		}

		p {
			color: #8e8f90;
			font-size: 16px;

			@media (max-width: $tablet) {
				font-size: 13px;
			}
		}

		a {
			color: #8e8f90;

			&:hover,
			&:focus {
				color: $white;
			}
		}

		.separator {
			margin: 0 10px;
		}

		img {
			opacity: 0.5;

			&:hover {
				opacity: 1;
			}
		}
	}
}

.e-news {
	background: $blue;
	padding: 30px;
	display: inline-block;
	width: 100%;

	@media (max-width: $tablet) {
		padding: 15px;
	}

	p {
		margin-bottom: 28px;
	}

	input {
		float: left;
		width: 75%;
		background: none;
		border: 1px #99a9ca solid;
		border-radius: 2px;
		height: 50px;
		padding: 0 15px;
		color: $white;

		@media (max-width: $xlarge) {
			width: 70%;
		}

		@media (max-width: $large) {
			width: 61%;
		}

		@media (max-width: $tablet) {
			width: 100%;
			height: 40px;
			font-size: 14px;
			margin-bottom: 10px;
		}

		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: $white;
		}

		&::-moz-placeholder { /* Firefox 19+ */
		  color: $white;
		}

		&:-ms-input-placeholder { /* IE 10+ */
		  color: $white;
		}

		&:-moz-placeholder { /* Firefox 18- */
		  color: $white;
		}
	}

	button {
		float: right;
		border: 2px $white solid;
		padding: 10px 0;
		width: 90px;
		color: $white;
		text-align: center;
		font-weight: bold;
		border-radius: 2px;
		background: none;
		cursor: pointer;

		@media (max-width: $tablet) {
			width: 100%;
			font-size: 14px;
			padding: 6px;
		}
	}
}