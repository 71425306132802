#pslider {
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .slides {
    padding:0;

    li {
      img {
        border: 1px solid #e4e4e4;
      }

      &.yt-slide {
        padding-bottom: 56.25%;
      }

    }
  }

}

#pcarousel {

  .slides {
    padding:0px 0 0 0;

    .slick-slide {
      margin: 0 10px;
    }

    // Fix external margins
    .slick-list {
      margin: 0 -10px;
    }
    .slick-slider {
      overflow: hidden;
    }

    // Move arrows on top of images
    .slick-prev {
      left: 50px;
      z-index: 1;
    }
    .slick-next {
      right: 50px;
    }


    li {
      position: relative;
      padding: 1px;
      text-align: center;
      cursor: pointer;

      img {
        display: inline-block;
        max-width: 100%;
        border: 1px solid #e4e4e4;

        &.yt-thumb {
          transform: translateY(-50%);
          position: relative;
          top: 50%;
        }
      }

      &.slick-current {
        img {
          border-color: $gray-500;
        }
      }

      .is-video {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 40%;
        z-index: 20;
      }
    }
  }


  .slick-dots {
    bottom: -40px;

    li {
      button {
        background: rgb(148, 148, 148);
      }

      &.slick-active {
        button {
          background: $blue-lighter;
          position: relative;

          &::after {
            position: absolute;
            left: -4px;
            top: -4px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px $blue-lighter solid;
            content: "";
          }
        }
      }
    }
  }

}

.show-on-owl-initialized {
  visibility: hidden;
}

.products-slider {

  .owl-dots {
    display: none;
  }
}

.slides {
  img {
    width: 100%;
  }
}


.flex-control-paging {
  position: absolute;
  bottom: -5px;
}