.menu {
	ul {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			list-style: none;
			text-transform: uppercase;

			a {
				display: inline-block;
				padding: 20px 25px 19px;

				&:hover,
				&:focus {
					color: $blue;
					text-decoration: none;
				}
			}

			&.active {
				a {
					background: $blue;
					color: $white;
				}
			}

			@media (max-width: $large) {
				a {
					padding: 20px 9px 19px;
				}
			}

			@media (max-width: $tablet) {
				width: 100%;

				a {
					width: 100%;
					padding: 10px 0;
				}
			}
		}
	}

	@media (min-width: $tablet-min) {
		display: block !important;
	}

	@media (max-width: $tablet) {
		display: none;
		position: absolute !important;
	    left: 0;
	    top: 50px;
	    width: 100%;
	    background: $black;
	    z-index: 10;
	    border-top: 1px rgba(255, 255, 255, 0.5) solid;
	}
}

.submenu {
	display: block;
	width: 100%;
	background: #e6e6e6;
	text-align: center;
	@media (max-width: $small) {
		overflow:auto;
	}
	ul {
		padding: 0;
		margin: 0;


		li {
			list-style: none;
			display: inline-block;
			margin: 0 24px;
			padding: 20px 0 14px;
			display: inline-block;
			border-bottom: 6px transparent solid;

			@media (max-width: $small) {
				margin: 0;
				float:left;
				padding: 12px 10px 6px;
			}

			&:hover {
				border-bottom: 6px $blue solid;

				a {
					color: $blue;
				}
			}

			&.active {
				border-bottom: 6px $blue solid;

				a {
					color: $blue;
				}
			}
		}
	}
}
