.btn {
	background: $blue;
	text-align: center;
	padding: 12px 0 11px;
	min-width: 200px;
	display: inline-block;
	border-radius: 2px;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 600;
	color: $white;
	border: 2px $blue solid;

	@media (max-width: $tablet) {
		min-width: 160px;
		font-size: 14px;
		padding: 10px 10px 8px;
	}

	&:focus,
	&:hover {
		background: none;
		border-color: $blue;
		color: $blue;
	}

	&.btn-to-white {
		&:hover {
			background: none;
			border-color: $white;
			color: $white;
		}
	}

	&-black {
		background: none;
		border-color: $black;
		color: $black;
	}
}