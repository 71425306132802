.single {
	.slick-slide {
		height: 662px;
		color: $white;

		@media (max-width: $xlarge) {
			height: 560px;
		}

		@media (max-width: $large) {
			height: 500px;
		}

		@media (max-width: $tablet) {
			height: 450px;
		}

		@media (max-width: $small) {
			height: 408px;
		}

		$slider-h1-size: 46px;
		h1,
		h2 {
			font-size: $slider-h1-size;
			font-weight: bold;
			text-transform: uppercase;

			@media (max-width: $xlarge) {
				font-size: 64px;
			}

			@media (max-width: $large) {
				font-size: 54px;
			}

			@media (max-width: $tablet) {
				font-size: 44px;
			}

			@media (max-width: $small) {
				font-size: 34px;
			}
		}
		h2 {
			font-size: $slider-h1-size - 20px;
			font-weight: normal;
			@media (max-width: $small) {
				display:none;
			}
		}

		p {
			font-size: 18px;
			line-height: 31px;
			margin-bottom: 40px;
			margin-top: 26px;
			font-weight: normal;

			@media (max-width: $tablet) {
				font-size: 16px;
				line-height: 26px;
				margin-bottom: 30px;
			}

			@media (max-width: $small) {
				font-size: 14px;
				line-height: 24px;
				margin-bottom: 20px;
			}
		}

		.container-fluid,
		.row,
		.col-md-8 {
			height: 100%;
		}

		.col-md-8 {
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
		}

		.slide-text {
			display: inline-block;
			width: 100%;
		}
	}
}

.slick-dots {
	position: absolute;
	left: 0;
	bottom: 20px;
	width: 100%;
	padding: 0;
	margin: 0;
	text-align: center;

	li {
		display: inline-block;
		list-style: none;
		margin: 0 6px;

		button {
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 0;
			background: rgba(255, 255, 255, 0.5);
			text-indent: -9999px;
			padding: 0;
			cursor:pointer;
		}

		&.slick-active {
			button {
				background: $blue-lighter;
				position: relative;

				&::after {
					position: absolute;
					left: -4px;
					top: -4px;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					border: 1px $blue-lighter solid;
					content: "";
				}
			}
		}
	}
}

main {
	.slick-dots {
		li {
			button {
				background:  #676767;
			}
		}
	}
}

.slick-arrow {
	position: absolute;
	width: 45px;
	height: 45px;
	text-indent: -9999px;
	border-radius: 50%;
	border: 2px #676767 solid;
	top: 20%;
	margin-top: -22px;
	cursor: pointer;
	z-index: 100;

	&.slick-prev {
		background: url('../images/icons/a-left-b.png') no-repeat center center;
		left: -50px;

		&:hover {
			background: $blue url('../images/icons/a-left.png') no-repeat center center;
		}

		@media (max-width: 1408px) {
			left: 0;
		}
	}

	&.slick-next {
		background: url('../images/icons/a-right-b.png') no-repeat center center;
		right: -50px;

		&:hover {
			background: $blue url('../images/icons/a-right.png') no-repeat center center;
		}

		@media (max-width: 1408px) {
			right: 0;
		}
	}

	&:hover {
		background-color: $blue;
		border-color: $blue;
	}
}

.news {
	@media (max-width: 920px) {
		padding-bottom: 40px;
	}

	.slick-dots {
		bottom: -30px;
	}
}

main {
	margin: 50px 0 75px;
}

.slide-card {
	padding: 0 15px;
}

.card {
	text-align: center;

	&:hover {
		background: url('../images/bg/gradient.png') repeat-x center bottom;
	}

	.card-body {
		padding: 30px;
	}

	img {
		width: 100%;
	}

	h4 {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 18px;
		margin-bottom: 0;

		a {
			color: $graytext;
		}

		@media (max-width: $tablet) {
			font-size: 16px;
		}

		@media (max-width: $small) {
			font-size: 14px;
		}
	}

	h2 {
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 25px;
	}

	p {
		color: #646464;
		margin-bottom: 30px;
	}

	&-wide {
		text-align: center;

		img {
			width: 100%;
			margin-bottom: 30px;
		}

		h2 {
			font-weight: bold;
			font-size: 40px;
			margin-bottom: 25px;

			@media (max-width: $large) {
				font-size: 35px;
			}

			@media (max-width: $tablet) {
				font-size: 30px;
			}

			@media (max-width: $small) {
				font-size: 24px;
			}
		}

		p {
			color: $black;
			margin-bottom: 30px;
		}
	}
}

.title {
	text-align: center;

	h2 {
		font-weight: bold;
	}
}

.article-box {
	height: 100%;
	padding-bottom: 20px;
}

.article {
	background-size: cover !important;
	color: $white;
	height: 100%;

	p {
		margin-bottom: 0;
	}

	a {
		display: inline-block;
		width: 100%;
		color: $white;
		padding: 30px;

		@media (max-width: $tablet) {
			padding: 24px;
		}
	}

	.transition{
		height:100%;
		-webkit-transition: background 300ms ease-in 20ms; /* property duration timing-function delay */
		-moz-transition: background 300ms ease-in 10ms;
		-o-transition: background 300ms ease-in 10ms;
		transition: background 300ms ease-in 10ms;

		&:hover {
			background: $blue !important;
			-webkit-transition: background 300ms ease-in 20ms; /* property duration timing-function delay */
			-moz-transition: background 300ms ease-in 10ms;
			-o-transition: background 300ms ease-in 10ms;
			transition: background 300ms ease-in 10ms;
		}
	}

}

.col-md-6 {
	.article {
		a {
			padding: 48px;

			@media (max-width: $tablet) {
				padding: 24px;
			}
		}
	}
}

.more {
	position: relative;
	text-align: center;
	margin-top: 30px;

	&::after {
		position: absolute;
		left: 0;
		top: -150px;
		width: 100%;
		height: 101px;
		background: url('../images/bg/transparent.png') repeat-x center bottom;
		content: "";
	}

	&::before {
		position: absolute;
		left: 0;
		top: 26px;
		width: 100%;
		height: 1px;
		border-bottom: 1px #c8c8c8 solid;
		content: "";

		@media (max-width: $tablet) {
			top: 21px;
		}
	}

	.btn {
		background: $white;
		z-index: 10;
		position: relative;
	}
}

.page {
	margin-bottom: 60px;

	h1 {
		margin-bottom: 24px;
		padding-left: 18px;
		position: relative;
		font-size: 35px;

		@media (max-width: $large) {
			font-size: 32px;
		}

		@media (max-width: $tablet) {
			font-size: 29px;
		}

		@media (max-width: $small) {
			font-size: 26px;
		}

		&::after {
			position: absolute;
			left: 0;
			top: 7px;
			border-left: 6px $blue solid;
			content: "";
			height: 28px;

			@media (max-width: $large) {
				top: 4px;
			}

			@media (max-width: $tablet) {
				top: 2px;
			}

			@media (max-width: $small) {
				top: 1px;
			}
		}
	}

}

.share {
	h4 {
		display: inline-block;
		padding-bottom: 4px;
		margin-bottom: 30px;
		font-size: 20px;
		color: #a0a0a0;
		border-bottom: 4px #a0a0a0 solid;

		@media (max-width: $small) {
			font-size: 14px;
		}
	}

	a {
		display: inline-block;
		width: 100%;
		margin-bottom: 30px;

		@media (max-width: $small) {
			img {
				width: 18px;
			}
		}
	}
}

.overview {
	display: inline-block;
	width: 100%;
	margin: 50px 0;
	border-top: 1px #c8c8c8 solid;
	border-bottom: 1px #c8c8c8 solid;
	padding: 40px 0 5px;

	h3 {
		color: $black;
	}

	h2 {
		font-size: 45px;
		margin: 0 0 10px;

		@media (max-width: $tablet) {
			font-size: 35px;
		}
	}

	h4 {
		font-size: 20px;
		margin-bottom: 35px;

		@media (max-width: $tablet) {
			font-size: 16px;
		}
	}
}

.partners {
	text-align: center;

	img {
		margin: 10px 20px;
		max-width: 100%;

		@media (max-width: $tablet) {
			margin: 10px 0;
		}
	}
}

.ccc {

	a {
      color: $blue;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
	}

	h2 {
		margin-top: 24px;
		margin-bottom: 10px;
		font-size: 25px;
		color: #000;
	}
	h3 {
		font-weight: normal;
		color: #626262;
		margin-bottom: 30px;
		font-size:26px;
	}
}
.news-category {
	h3 {
		color:#fff;
		font-weight: bold;
	}
}

.subcategory-item {

	padding-top:10px;
	padding-bottom:10px;

	.cat-pic {
		border:1px solid #d1d1d1;
		display:flex;
		max-width: 180px;
		&:before {
			content: "";
			display: block;
			padding-top: 100%;
		}

		img {
			width:100%;
			height:100%;
		}
	}
	p {
		max-width: 180px;
		text-align: center;
	}
}

.row-bordered:before {
	content: "";
	display: block;
	border-bottom: 1px solid $gray-500;
	margin: 24px 10px 36px 10px;
	width:100%;
}