.form-wrap {
  background:#E6E6E6;
  padding: 40px 30px;
  width:auto;

  input {
    width: 100%;
    border: 1px solid $gray-500;
    padding:10px 16px;
    font-size:14px;

  }
  textarea  {
    width: 100%;
    padding:10px 16px;
    border: 1px solid $gray-500;
    height:130px;
    font-size:14px;
    display:block;
  }

  input[type=submit] {
    border:2px solid #000;
    width: auto;
    background:transparent;
    font-weight: 600;
    font-size:15px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover{
      color:#fff;
      background: $blue;
    }
  }

  .field-row {
    margin:0 0 20px 0;

    .error_msg{
      font-size:12px;
      display:block;
      width:100%;
      color:#fff;
      padding:2px 10px;
      background:#c53d3d;
    }

  }

  label { display:inline-block; font-size:12px; margin-bottom:0;}

}

.css-checkbox {
  display:inline-block;
}