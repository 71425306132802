@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800&subset=latin-ext');

.container-fluid {
	max-width: $container-fluid;
}

html,
body {
	width: 100%;
	height: 100%;
}

body {
	font-family: 'Roboto', sans-serif;
}

a {
	color: $black;

	&:hover,
	&:focus {
		color: $black;
		outline: none!important;
		text-decoration: none;
	}
}

p {
	font-size: 17px;
	line-height: 34px;

	@media (max-width: $large) {
		font-size: 16px;
		line-height: 28px;
	}

	@media (max-width: $tablet) {
		font-size: 15px;
		line-height: 26px;
	}

	@media (max-width: $small) {
		font-size: 14px;
		line-height: 25px;
	}
}

*:focus {
    outline: none !important;
}

.locked {
	overflow: hidden;
	position: fixed;
	width: 100%;
	height: 100%;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.m-b-50 {
	margin-bottom: 50px;
}

hr {
	margin: 0 0 50px;
	border-color: #c8c8c8;
}

h2 {
	font-size: 35px;
	line-height: 41px;
	margin-bottom: 30px;

	@media (max-width: $large) {
		font-size: 32px;
		margin-bottom: 38px;
	}

	@media (max-width: $tablet) {
		font-size: 29px;
		margin-bottom: 35px;
		margin-bottom: 20px;
	}

	@media (max-width: $small) {
		font-size: 26px;
		margin-bottom: 30px;
	}
}

h3 {
	font-size: 25px;
	line-height: 31px;
	margin-bottom: 15px;
	font-weight: bold;

	@media (max-width: $large) {
		font-size: 22px;
		line-height: 28px;
	}

	@media (max-width: $tablet) {
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 10px;
	}

	@media (max-width: $small) {
		font-size: 19px;
		line-height: 25px;
	}
}