.product-item {

	padding:0 0 20px 0;
	margin:0 0 30px 0;

	h2 {
		font-weight: 300;
		font-size: 30px;
		line-height: 40px;
	}

	.product-top{

		ul {

			list-style-type: none;
			padding:0;

			li {
				padding:0 0 10px 20px;
				background:url('../images/icons/liarrow.png') 0 6px no-repeat;
			}
		}
	}

}