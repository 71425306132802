header {
	display: block;
	width: 100%;
	background: $black;
	color: $white;
	position: relative;
	z-index: 1000;

	@media (max-width: $tablet) {
		* {
			position: unset !important;
		}
	}

	a {
		color: $white;

		&:hover,
		&:focus {
			color: $white;
			text-decoration: underline;
		}
	}

	.logo {
		margin-top: 9px;

		@media (max-width: $tablet) {
			width: 100px;
			margin-top: 7px;
		}

		@media (max-width: $small) {
			width: 80px;
			margin-top: 11px;
		}
	}
}

.info {
	float: right;

	a {
		padding: 20px 0 19px;
		display: inline-block;
		margin-left: 36px;

		@media (max-width: $tablet) {
			margin-left: 20px;
			padding: 13px 0;
		}

		@media (max-width: $small) {
			margin-left: 15px;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}

.hamburger {
	display: none;
	background: none;
	border: 0;

	@media (max-width: $tablet) {
		display: block;
		float: right;
		width: 47px;
		margin-top: 8px;
		margin-left: 15px;
	}

	@media (max-width: $small) {
		margin-top: 7px;
	}

	span {
		float: left;
		width: 35px;
		height: 3px;
		background-color: $white;
		margin: 4px 0;

		@media (max-width: $small) {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.breadcrumbs {
	ul {
		padding: 0;
		margin: 10px 0 30px;

		li {
			list-style: none;
			display: inline-block;
			margin: 0 12px 0 0;
			padding-right: 12px;
			display: inline-block;
			color: #626262;
			font-size: 16px;
			text-transform: uppercase;

			@media (max-width: $small) {
				font-size: 12px;
			}

			a {
				color: #626262;
				position: relative;

				&:hover {
					color: $blue;
				}

				&::after {
					position: absolute;
					right: -16px;
					top: -2px;
					content: "/";
					color: #626262;
				}
			}
		}
	}
}
